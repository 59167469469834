'use strict';
!function($) {

    const theme = {
        init : () => {
            theme.validatePatternOfTextarea();
            theme.replaceStropheInInputsAndTextarea();
        },
        
        validatePatternOfTextarea: () => {
            $(this).find("textarea").on("input change propertychange", function() {
                var errorMsg = "Veuillez correspondre au format demandé.";
                var pattern = $(this).attr("pattern");
        
                if (typeof pattern == undefined || pattern == null) return;
                pattern = new RegExp("^" + pattern.replace(/^\^|\$$/g, "") + "$","g");
        
                // Vérifier chaque ligne de texte
                // $.each($(this).val().split("\n"), function () {
                    // Vérifier si la ligne correspond au motif
                    var hasError = !$(this).val().match(pattern);
                    // ===
                    if(typeof this.setCustomValidity == "function") {
                        this.setCustomValidity(hasError ? errorMsg : "")
        
                    } else {
                        // Non supporté par le navigateur, retour à l'affichage manuel des erreurs...
                        $(this).toggleClass("error", !!hasError);
                        $(this).toggleClass("ok", !hasError);
        
                        if (hasError) {
                            $(this).attr("title", errorMsg)
                        } else {
                            $(this).removeAttr("title")
                        };
                    };
                    // return !hasError;
                // });
            });
        },

        replaceStropheInInputsAndTextarea: () => {
            $("textarea, input[type=text]").on("input", function(e) {
                var val = e.target.value;
                e.target.value = val.replace(/\'/g, "’")
                // val.replace(/'/g, "\u2019")
            });
        }
    };

    theme.init();

}(jQuery);
